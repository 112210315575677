<template>
  <v-app>
    <v-main>
      <a href="#" @click.prevent="toggleRegisterForm" id="main-registration-link" style="color: black">.</a>
      <v-container :style="{ height: '0px', padding: '0px', margin: '0px' }">
        <v-row class="text-center">
          <v-col cols="12">
            <div class="text-center">
              <v-dialog
                v-model="formVisible"
                fullscreen
                hide-overlay
                scrollable
                transition="dialog-bottom-transition"
              >
                <v-card>
                  <v-card-title
                    class="headline white--text"
                    style="background: #63376A"
                  >
                    Регистрация
                  </v-card-title>
                  <v-card-text>
                    <v-row>
                      <v-col cols="12" md="6" offset-md="3" lg="6" offset-ld="3" sm="8" offset-sm="2"
                             class="elevation-4" style="background: rgba(0,0,0,0.03)">
                        <v-card-text>
                          <v-form v-model="formValid" ref="regForm" lazy-validation>
                            <v-row>
                              <v-col cols="12" md="6" lg="6" sm="6" :style="{padding: colsPadding}">
                                <v-text-field
                                  dense
                                  label="Фамилия"
                                  clearable
                                  color="purple darken-2"
                                  required
                                  outlined
                                  :rules="[rules.required]"
                                  v-model="fields.last_name"
                                ></v-text-field>
                              </v-col>
                              <v-col cols="12" md="6" lg="6" sm="6" :style="{padding: colsPadding}">
                                <v-text-field
                                  dense
                                  label="Имя"
                                  clearable
                                  color="purple darken-2"
                                  required
                                  outlined
                                  :rules="[rules.required]"
                                  v-model="fields.first_name"
                                ></v-text-field>
                              </v-col>
                              <v-col cols="12" :style="{padding: colsPadding}">
                                <v-text-field
                                  dense
                                  label="Отчество"
                                  clearable
                                  hint="Опционально"
                                  persistent-hint
                                  color="purple darken-2"
                                  required
                                  outlined
                                  v-model="fields.mid_name"
                                ></v-text-field>
                              </v-col>
                            </v-row>
                            <v-row>
                              <v-col cols="12" md="6" lg="6" sm="6" :style="{padding: colsPadding}">
                                <v-text-field
                                  dense
                                  label="Email"
                                  outlined
                                  clearable
                                  color="purple darken-2"
                                  required
                                  :rules="[rules.required, rules.email]"
                                  v-model="fields.Email"
                                ></v-text-field>
                              </v-col>
                              <v-col cols="12" md="6" lg="6" sm="6" :style="{padding: colsPadding}">
                                <v-text-field
                                  dense
                                  label="Пароль"
                                  :type="showPasswordContent ? 'text' :'password'"
                                  outlined
                                  clearable
                                  color="purple darken-2"
                                  required
                                  :rules="[rules.required, rules.password]"
                                  v-model="fields.Password"
                                  :append-icon="!showPasswordContent ? 'mdi-eye' : 'mdi-eye-off'"
                                  @click:append="() => (showPasswordContent = !showPasswordContent)"
                                >
                                  <template v-slot:prepend>
                                    <v-tooltip top>
                                      <template v-slot:activator="{ on }">
                                        <v-icon v-on="on" @click="generatePwd" color="#63376a">
                                          mdi-lock-plus
                                        </v-icon>
                                      </template>
                                      Сгенерировать пароль
                                    </v-tooltip>
                                  </template>
                                </v-text-field>
                              </v-col>
                              <v-col cols="12" md="6" lg="6" sm="6" :style="{padding: colsPadding}">
                                <v-select
                                  dense
                                  label="Специализация"
                                  outlined
                                  required
                                  clearable
                                  :rules="[rules.required]"
                                  color="purple darken-2"
                                  v-model="fields.speciality"
                                  :items="specialities"
                                  item-value="name"
                                  item-text="name"
                                ></v-select>
                              </v-col>
                              <v-col cols="12" md="6" lg="6" sm="6" :style="{padding: colsPadding}">
                                <v-text-field
                                  dense
                                  label="Город"
                                  hint="Опционально."
                                  persistent-hint
                                  outlined
                                  clearable
                                  color="purple darken-2"
                                  v-model="fields.city"
                                ></v-text-field>
                              </v-col>
                              <v-col cols="12" md="12" lg="12" sm="12" :style="{padding: colsPadding}">
                                <vue-recaptcha
                                  ref="recaptcha"
                                  size="invisible"
                                  sitekey="6Lfl4twZAAAAAEy6KaiaxrzXroeoL5KtKhlPUJsr"
                                  badge="bottomright"
                                  @expired="onCaptchaExpired"
                                  @verify="validateRecaptcha"
                                >
                                </vue-recaptcha>
                              </v-col>
                            </v-row>
                          </v-form>
                        </v-card-text>
                        <v-divider></v-divider>
                        <v-card-actions>
                          <v-btn
                            color="primary"
                            small
                            class="white--text"
                            @click="sendForm"
                            :disabled="!formValid"
                            :loading="sendButtonLoading"
                          >
                            Отправить
                          </v-btn>

                          <v-spacer></v-spacer>

                          <v-btn
                            color="#63376A"
                            small
                            class="white--text"
                            @click="toggleRegisterForm"
                          >
                            Закрыть
                          </v-btn>
                        </v-card-actions>
                      </v-col>
                    </v-row>
                  </v-card-text>
                </v-card>
              </v-dialog>
            </div>
            <v-snackbar
              v-model="showAlertMessage"
              multi-line
              :timeout="alertMessage.timeout"
              :color="alertMessage.color"
              right
              top
              :style="{ 'z-index': 99999999 }"
            >
              {{ this.alertMessage.text }}
            </v-snackbar>
          </v-col>
        </v-row>
      </v-container>
    </v-main>
  </v-app>
</template>

<script>
import axios from 'axios'
import VueRecaptcha from 'vue-recaptcha'

export default {
  name: 'RegisterForm',
  components: { VueRecaptcha },

  data: () => ({
    formVisible: false,
    formValid: false,
    colsPadding: '2px 12px',
    specialities: [],
    sendButtonLoading: false,
    fields: {
      last_name: '', // f
      first_name: '', // i
      mid_name: '', // o
      city: '',
      // Login: '',
      Email: '',
      Password: '',
      speciality: '',
      come_from: location.href,
      gRecaptchaToken: false,
      tags: ['lvRegistration']
    },
    recaptchaSiteKey: '6Lfl4twZAAAAAEy6KaiaxrzXroeoL5KtKhlPUJsr',
    rules: {
      required: v => (v || '').length > 0 || 'поле обязательно для заполнения',
      email: v => {
        const pattern = /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/
        return pattern.test(v) || 'Требуется корректный Email адрес'
      },
      password: v => {
        // const pattern = /^(?=.*[a-z])(?=.*[A-Z])(?=.*[0-9])(?=.*[!@#$%^&*])(?=.{8,})/
        const pattern = /^(?=.*[a-zA-Z])(?=.*[0-9])(?=.{8,})/
        return (pattern.test(v) || 'Пароль должен быть минимум 8 символов и содержать латинские буквы И цифры')
      }
      // eightCharsLength: v => {
      //   return (v.length >= 8 || 'Минимальная длина 8 символов')
      // },
      // containNumbers: v => {
      //   const pattern = /^(?=.*[0-9])/
      //   return (pattern.test(v) || 'Должна содержаться хотя бы одна цифра')
      // },
      // containLowerAndUpperCase: v => {
      //   const pattern = /^(?=.*[a-z])(?=.*[A-Z])/
      //   return (pattern.test(v) || 'Должна содержаться латинские буквы И верхнего И нижнего регистра')
      // },
      // containSpecialChars: v => {
      //   const pattern = /^(?=.*[!@#$%^&*(){}])/
      //   return (pattern.test(v) || 'Должен содержаться хотя бы один символ из !@#$%^&*(){}')
      // }
    },
    showPasswordContent: false,
    showAlertMessage: false,
    alertMessage: {
      color: 'primary',
      text: 'текст',
      timeout: 5000
    },
    pwdData: {
      numbers: '0123456789',
      chars: 'abcdefghijklmnopqrstuvwxyzABCDEFGHIJKLMNOPQRSTUVWXWZ',
      minsymbols: 8,
      maxsynmols: 12,
      numbersAmount: 3
    }
  }),
  methods: {
    onCaptchaExpired () {
      this.fields.gRecaptchaToken = false
      this.$refs.recaptcha.reset()
    },
    validateRecaptcha (recaptchaToken) {
      this.fields.gRecaptchaToken = recaptchaToken
      this.sendForm()
    },
    toggleRegisterForm () {
      this.formVisible = !this.formVisible
    },
    sendForm () {
      if (!this.fields.gRecaptchaToken) {
        this.$refs.recaptcha.execute()
        return
      }
      this.sendButtonLoading = true
      const vm = this

      axios.post('execute', this.fields)
        .then(function (response) {
          const data = response.data
          const msg = data.message
          if (data.success) {
            vm.showAlert(msg, 'success', 10000)
            vm.formVisible = false
          } else {
            vm.showAlert(msg, 'error', 10000)
            vm.onCaptchaExpired()
          }
        })
        .catch(function (error) {
          vm.showAlert(error, 'error', 10000)
          console.log(error)
        })
        .then(function () {
          vm.sendButtonLoading = false
        })
    },
    showAlert (text, color, timeout) {
      if (text) {
        this.alertMessage.text = text
      }

      if (color) {
        this.alertMessage.color = color
      }

      if (timeout) {
        this.alertMessage.timeout = timeout
      }
      this.showAlertMessage = true
    },
    hideAlertMessage () {
      this.showAlertMessage = false
    },
    generatePwd () {
      const pwdLength = this.pwdData.minsymbols + Math.floor((this.pwdData.maxsynmols - this.pwdData.minsymbols) * Math.random())
      const numbersAmount = 1 + Math.floor((pwdLength - 1) * Math.random())
      const charsAmount = pwdLength - numbersAmount
      let result = ''

      for (let k = 0; k < charsAmount; k++) {
        result += this.pwdData.chars.charAt(Math.floor(Math.random() * this.pwdData.chars.length))
      }

      for (let k = 0; k < numbersAmount; k++) {
        result += this.pwdData.numbers.charAt(Math.floor(Math.random() * this.pwdData.numbers.length))
      }
      result = result.split('')
      result.sort(function () { return Math.random() - 0.5 })
      result = result.join('')
      this.fields.Password = result
      console.log(result)
    }
  },
  mounted () {
    const vm = this
    if (window.lvPremium) {
      this.fields.tags.push('lvPremium')
    }
    axios.get('/specialities-list', {})
      .then(function (response) {
        vm.specialities = response.data
      })
      .catch(function (error) {
        console.log(error)
      })
  },
  updated () {
    if (this.$refs.regForm) {
      this.$refs.regForm.validate()
    }
  }
}
</script>
<style lang="scss">
  .v-application--wrap {
    min-height: auto!important;
  }
</style>
