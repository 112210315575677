import Vue from 'vue'
import App from './App.vue'
import vuetify from './plugins/vuetify'
import axios from 'axios'
import Qs from 'qs'

// eslint-disable-next-line no-undef
axios.defaults.baseURL = apiHost + '/register/'
// axios.defaults.headers.post['Content-Type'] = 'application/x-www-form-urlencoded'

// to enable nested query params (Qs required)
axios.interceptors.request.use(config => {
  config.paramsSerializer = params => {
    return Qs.stringify(params, {
      arrayFormat: 'brackets',
      encode: false
    })
  }
  // if (!Object.prototype.hasOwnProperty.call(config, 'params')) {
  //   config.params = {}
  // }
  // // eslint-disable-next-line no-undef
  // // config.params['access-token'] = jwt

  return config
})

Vue.config.productionTip = false

new Vue({
  vuetify,
  render: h => h(App)
}).$mount('#register-form')
